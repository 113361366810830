// IMPORTS
@import (reference) "settings.less";

// PRODUCERS BANNER
.producers-banner {
    padding: 48px 70px;
    position: relative;
    --swiper-navigation-sides-offset: 0;

    .swiper-slide {
        width: 180px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: filter .2s ease-in-out;
        padding: 15px 30px;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    &:before {
        .hr-after();
        top: 0;
    }
}

@media (min-width: 1280px) {
    .producers-banner {

        .swiper-slide {
            width: ~"calc((100% - (5 * 44px)) / 6)";
            margin-right: 61px;
            filter: grayscale(1);

            &:hover {
                filter: none;
            }
        }
    }
}

.producers-banner + #newsletter {
    margin-top: calc(-1 * var(--rowGap));
}

// NEWSLETTER
#newsletter {
    position: relative;
    color: white;
    padding-block: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px 64px;
    flex-wrap: wrap;

    &:before {
        .full-bg();
        background: @main-color;
    }

    &.unchecked .newsletter-clauses-wrapper:after {
        content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
        color: @error-color;
        margin-top: 10px;
    }
}

.newsletter-header {
    text-align: start;
    display: flex;
    flex-direction: column;

    b {
        .headline;
        margin-bottom: 2px !important;
        display: block;
        color: white;
    }
}

.newsletter-input {
    width: 508px;
    position: relative;

    input[type="text"] {
        width: 100%;
        height: 48px;
        background-color: transparent;
        border: 1px solid @border-color;
        color: white;
        padding-right: 56px;
        font-size: 16px;
        font-family: @main-font-family;

        &:focus::placeholder {
            color: transparent;
        }

        &::-webkit-input-placeholder {
            color: white;
        }

        &::-moz-placeholder {
            color: white;
        }

        &:-ms-input-placeholder {
            color: white;
        }

        &:-moz-placeholder {
            color: white;
        }
    }

    .popup-trigger {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        color: white;
        width: 54px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        border-radius: var(--borderRadius);

        &:after {
            .small-icon();
            font-size: 24px;
            content: "\e919";
        }

        &:hover {
            color: @footer-gray;
        }
    }
}

.newsletter-clauses-wrapper.unchecked:after {
    content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
    clear: both;
    color: @error-color;
    margin-top: 10px;
}

// FOOTER
#footer {
    position: relative;

    &:before {
        .full-bg();
        background: @header-color;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding-block: var(--rowGap);
    color: @footer-gray;
    gap: 24px;

    > * {
        display: flex;
        flex-direction: column;
        max-width: 40%;

        > strong {
            font-size: 14px;
            line-height: 1.2;
            letter-spacing: 0.08em;
            display: block;
            margin-bottom: 24px;
            text-transform: uppercase;
            color: white;
        }
    }
}

.footer-submenu {

    > ul {
        margin-top: -10px;
    }

    a {
        display: inline-block;
        padding: 10px 0;
        color: @footer-gray;

        @media (min-width: 1280px) {
            &:hover {
                color: white;
            }
        }
    }
}

.location-hours {
    flex-basis: 17%;
    display: flex;
    flex-direction: column;

    > div {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        padding-left: 36px;
        position: relative;
        gap: 4px;

        svg {
            width: 24px;
            aspect-ratio: 1;
            color: white;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &:has(.hours) .location {
        margin-bottom: 24px;
    }
}

.footer-contact {

    > b {
        margin-bottom: 16px;
    }

    .contact-list {
        display: flex;
        flex-direction: column;
        margin-block: -6px;

        a {
            padding: 6px 0;
            color: @footer-gray;
            display: flex;
            align-items: center;
            position: relative;
            gap: 12px;

            &:before {
                .small-icon();
                color: white;
            }
        }

        .email:before {
            content: "\e919";
        }

        .phone:before {
            content: "\e91a";
        }
    }
}

.footer-map {
    width: 362px;
    height: 208px;
    margin-top: 41px;
    position: relative;

    iframe {
        .img-crop();
    }

    &:before {
        .triangle-decal();
        background: @header-color;
    }
}

.footer-socials {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 4px;

    &:before {
        content: 'Znajdź nas';
        padding-right: 8px;
    }

    a {
        color: white;

        svg,
        &:before {
            .small-icon();
            transition: color 0.1s ease-in-out;
        }

        &.facebook-link:before {
            content: "\e925";
        }

        &:hover {
            color: @footer-gray;
        }
    }
}

.footer-bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    position: relative;
    border-top: 1px solid rgba(255,255,255, 0.2);

    a, span {
        color: @footer-gray;
    }
}